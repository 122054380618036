<template>

  <div id="chat" class="overflow-x-hidden">

    <div v-if="!selectedUser" class="text-center h-100 d-flex align-items-center justify-content-center">
      <div class="text-center py-4">
        <img src="@assets/images/start_chat.svg" class="img-elements-not-found max-w-full" alt="" width="400" />
        <div class="mt-5" style="font-size:1.1rem">
          Επιλέξτε ένα χρήστη από τη λίστα για να ξεκινήσετε τη συνομιλία.
        </div>
      </div>


    </div>

    <div v-else class="chat position-relative">

      <div class="chat-user-bar">
        <div class="grid grid-cols-2 align-items-center">

          <div>
            <div class="d-flex align-items-center">
              <div class=" mobile-only mr-2">
                <b-button size="xs" variant="primary" class="mobile-only rounded-btn" @click="$store.dispatch('chat/setSelectedUser', null)">
                  <span class="fe-chevron-left"></span>

                </b-button>
              </div>
              <div class="">
                {{selectedUserInfo.fullName}} <span class="text-primary-muted">({{selectedUserInfo.email}})</span>
              </div>
            </div>

          </div>
          <div class="text-right">
            <b-button variant="light" size="xs" @click="viewAthlete">
              Εύρεση καρτέλας</b-button>
          </div>
        </div>

      </div>

      <div class="chat-bubbles-main-wrap">
        <div class="chat-bubbles-wrapper">
          <infinite-loading ref="infinite-loader" :key="selectedUser"
                            direction="top" @infinite="infiniteHandler">
            <div slot="spinner" class="p-2">Φορτώνει...</div>
            <div slot="no-more">Δεν υπάρχουν άλλα μηνύματα!</div>
            <div slot="no-results">Δεν υπάρχουν μηνύματα!</div>

          </infinite-loading>
          <div v-for="message of messages" class="chat-bubble" :class="message.from === user.id ? 'me' : ''">
            <div v-if="message.from !== user.id" class="user-thumbnail">
              <img v-if="selectedUserInfo.image" :src="selectedUserInfo.image" class="image-rounded" style="border-radius:100%;">
              <span v-else>
                  {{ selectedUserInfo.thumbnail}}
            </span>

            </div>
            <p>
              {{message.text}}
            </p>

            <span class="date">{{formatDate(message.createdAt)}}
          </span>

          </div>

        </div>
      </div>


      <div class="send-message">
        <b-input-group class="mt-3">
          <b-form-input v-model="message" placeholder="Πληκτρολογήστε εδώ..."  @keyup.enter="sendMessage"></b-form-input>
          <b-input-group-append>
            <b-button variant="primary" @click="sendMessage" :disabled="sending"><i class="fas fa-paper-plane"/> Αποστολή</b-button>
          </b-input-group-append>
        </b-input-group>


      </div>
    </div>

  </div>

</template>

<style lang="scss">
#chat{
  height:calc(100vh - $topbar-height);
  overflow-y:auto;
}
.chat{
  position:relative;
  .chat-bubbles-wrapper{
    padding-top:50px!important;
    padding-left:5px;
    padding-right:5px;
    height:calc(100vh - 150px);
    overflow-y:auto;
    @media(max-width:767px){
      height:calc(100vh - 200px);
    }
  }
  .send-message{
    padding:0 1.5em;
  }
}
.chat-user-bar{
  position:absolute;
  top:0;
  left:0;
  right:0;
  z-index:2;
  height:50px;
  color:$color_primary;
  padding:7px;
  background-color:#F8FAFF;
  border-bottom:1px solid $color_primary_low_opacity;
  font-size:11px;
  font-weight:700;

  @media(min-width:768px){
    font-size:12px;
    padding:10px;
  }
  @media(min-width:1240px){
    font-size:13px;
    padding:15px;
  }
}
.chat-bubble{
  width:250px;
  margin:1em;
  border-radius:12px;
  background-color: #F8FAFF;
  padding:.5rem 1rem;
  p{
    margin-bottom:0;
  }
  .date{
    font-size:0.6rem;
  }
  &.me{
    margin-left:auto;
    text-align:right;
    color:#fff;
    background-color:$color_primary;
  }
}
</style>
<script>
import {mapState} from "vuex";
import InfiniteLoading from 'vue-infinite-loading';
export default{
  components: {InfiniteLoading},

  computed:{

    ...mapState({
      user: state => state.auth.currentUser,
      selectedUser: state => state.chat.selectedUser,
      selectedUserInfo: state => state.chat.selectedUserInfo,
      mobileToken: state => state.auth.mobileToken
    }),

  },

  watch:{
    selectedUser(){
      this.messages = [];
      this.page = 1;
      this.totalMessages = 0;
      clearInterval(this.syncTimeout);
      this.syncTimeout = setInterval(()=>{
        this.checkForNewMessages();
      }, 10000);
    },

  },
  data(){
    return{
      page: 1,
      limit:20,
      messages: [],
      totalMessages: 0,
      message: '',
      sending: false,
      syncTimeout: null
    }
  },

  mounted(){
    this.syncTimeout = setInterval(()=>{
      this.checkForNewMessages();
    }, 10000);
  },

  destroyed() {
    if(this.syncTimeout) clearInterval(this.syncTimeout);
  },
  methods:{

    infiniteHandler($state){
      if(!this.selectedUser) return;
      if(this.messages.length === this.totalMessages.length){
        $state.complete();
        return;
      }
      this.fetchChatMessages($state);


    },

    checkForNewMessages(){

      if(!this.selectedUser) return;
      this.$axios.get(process.env.VUE_APP_MOBILE_API + "/chat",{
        params:{offset: 0, limit: this.limit, id: this.selectedUser, lastId: this.messages.length > 0 ? this.messages[this.messages.length -1].id : '' },
        headers: {'x-dietitian-token': this.mobileToken}}).then(res => {

          for(let item of res.data.rows){
            this.messages.push(item);
          }

      }).catch(e=>{

      });
    },

    fetchChatMessages($state){

        const height = this.$refs['infinite-loader'].scrollParent.scrollHeight;
        if(!this.selectedUser) return;
        this.$axios.get(process.env.VUE_APP_MOBILE_API + "/chat",{
          params:{offset: (this.page-1) * this.limit, limit: this.limit, id: this.selectedUser },
          headers: {'x-dietitian-token': this.mobileToken}
        }).then(result  =>{
          if($state && result.data.rows.length > 0){
            this.page+=1;

          }else{
            $state.complete();
          }

          this.messages.unshift(...result.data.rows);
          this.messages.sort(function(a, b) {
            return a.id - b.id;
          });

          this.totalMessages = result.data.count;
          if($state){
            this.$nextTick(()=> {
              let el = this.$refs['infinite-loader'].scrollParent // this
              el.scrollTop = el.scrollHeight - height; //thi
            })

            $state.loaded();

          }

        }).catch((e)=>{
        }).finally(()=>{
        });

    },

    sendMessage(){
      if(!this.message || this.message.length === 0) return;
      if(this.sending) return; // avoid double send.
      this.sending = true;
      const data = {
        id: this.selectedUser,
        text: this.message,
      }
      this.$axios.post(process.env.VUE_APP_MOBILE_API + "/chat/reply",{message: data}).then(result  =>{
        this.messages.push(result.data);
        this.message = '';
        this.$nextTick(()=>{
          let el = this.$refs['infinite-loader'].scrollParent // this
          el.scrollTop = el.scrollHeight; //thi
        })

      }).catch((e)=>{
        console.log(e);
      }).finally(()=>{
        this.sending = false;
      });
    },

    viewAthlete(){
      this.$router.push({name: 'athletes', query: {email: this.selectedUserInfo.email}})
    }
  }

}
</script>
